.support {
  font-size: 0.75em;
  line-height: 1.3em;
  margin-top: 5em;
  text-align: center;
  color: #242424;
}

.desc {
    margin-top: 2em;
    color: #424242;
}


.support img {
  max-width: 70%;
}

.sketch-picker, .twitter-picker {
  margin: auto;
  max-width: 205px;
}

.limits {
  font-size: 0.8em;
}

.line {
  display: inline-block;
  margin-left: 0.3em;
}