.userSection {
  background-color: #2c3e50;
  flex: 4 1 90px;
  order: 1;
  min-width: 90px;
}

.activePlayers .userActive::before {
  content: "\2713";
}

.userSection ul {
    font-family: 'Oswald';
    font-size: 1.2em;
  margin-top: 0;
}

.userSection > button {
  margin-bottom: 3px;
}

.userActive {
    color: #2980b9;
}

.userInactive, .observerUsers .userActive {
    color: #7f8c8d;
}

.userSelf.userActive {
  color: #25aceb;
}

.permission100::before {
    content: "C";
}

.permission80::before {
    content: "M";
}

.permission100, .permission80 {
    font-size: 0.45em;
    color: #f39c12;
    margin-left: 0.45em;
    font-family: 'Verdana';
}

.modButton {
  display: inline-block;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 0.6em;
  padding: 0.1em;
  vertical-align: middle;
  cursor: pointer;
}

.userActive .username {
  vertical-align: middle;
}


/* Small screens */
@media all and (max-width: 860px) {
  .userSection {
    font-size: 1.7em;
  }
  .userSection {
    order: 3;
    min-width: 280px;
    flex-basis: 280px;
  }
}