.bookListSection {
  background-color: #f39c12;
  flex: 7 1 150px;
  order: 4;
  min-width: 150px;
  user-select: none;
}


.bookListSection > ul {
  font-size: 0.9em;
  font-family: sans-serif;
}

.bookListSection > ul > li {
  background-color: rgba(255,255,255,0.4);
  margin: 2px 0;
}

.bookBeingPresented .bookTitle {
  font-weight: bold;
}

.bookAtInactiveUser .bookTitle {
  font-style: italic;
  color: red;
}

.bookExpanded {
  font-size: 0.9em;
  margin-left: 4px;
  color: #222;
}

.bookTitle {
  cursor: pointer;
}

.bookPage {
  font-size: 0.9em;
  color: #444;
}

.roomCodeCode {
  font-family: 'Oswald';
  font-size: 1.2em;
  margin-top: 0;
  user-select: text;
}

.roomCodeInstructions {
  color: #444;
  font-size: 0.8em;
}


/* Small screens */
@media all and (max-width: 860px) {
  .bookListSection {
    font-size: 1.7em;
  }
}