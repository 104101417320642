.authYes {
  flex: 1;
  display: flex;
}

.authNo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*.presentationSet, .moveSet, .userSection, .gameListSection, .roomSection, .createRoomSection, .descriptionSection  {*/
.rounded {
  border-radius: 10px;
  padding: 7px;
}


/***************************************/


.referbar {
  margin-right: 5px;
}

ul {
    list-style: none;
    padding: 0;
}

.roomSection ul {
  min-width: 400px;
  overflow-y: auto;
  height: 40vh;
  background: #1abc9c;
      padding: 10px;
    border-radius: 10px;
    margin: 0 0 10px 0;
}

.bookCreationSection li {
  cursor: pointer;
  user-select: none;
}

.roomSection li:hover, .bookCreationSection li:hover,
.roomSection li:focus, .bookCreationSection li:focus
{
    color: #c0392b;
    text-shadow: 1px 1px 1px black;
}

.activeCount {
    font-size: 0.75em;
    margin-left: 0.5em;
  color: #545e5f;
    text-shadow: none;
}

.tag {
  max-height: 1em;
  margin-left: 0.25em;
}

.moveGameDescription {
    color: #2f2412;
}

.firstItemDescription {
  font-style: italic;
}

.sendOptions {
  display: grid;
  grid: auto-flow / 20px 1fr;
  gap: 0px 4px;
  font-size: 20px;
  margin-left: 57px;
}

.modControls input {
  width: 90%;
}



.opacityOnly-enter {
  opacity: 0.01;
}

.opacityOnly-enter.opacityOnly-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.opacityOnly-leave {
  opacity: 1;
}

.opacityOnly-leave.opacityOnly-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}



.heightStretch-enter {
  opacity: 0.01;
  max-height: 0;
}

.heightStretch-enter.heightStretch-enter-active {
  opacity: 1;
  max-height: 800px;
  transition: max-height 500ms, opacity 500ms ease-in;
}

.heightStretch-leave {
  opacity: 1;
  max-height: 800px;
}

.heightStretch-leave.heightStretch-leave-active {
  opacity: 0.01;
  max-height: 0;
  transition: max-height 500ms, opacity 500ms ease-in;
}



.activeMovesTransition-enter {
  opacity: 0.01;
  max-height: 0;
    overflow: hidden;
}

.activeMovesTransition-enter.activeMovesTransition-enter-active {
  opacity: 1;
  max-height: 800px;
    overflow: hidden;
  transition: max-height 500ms, opacity 500ms ease-in;
}

.activeMovesTransition-leave {
  opacity: 1;
  max-height: 800px;
    overflow: hidden;
}

.activeMovesTransition-leave.activeMovesTransition-leave-active {
  opacity: 0.01;
  max-height: 0;
    overflow: hidden;
  transition: max-height 500ms, opacity 500ms ease-in;
}


button {
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #c0392b;
  border: 0;
  border-bottom: 2px solid #b53224;
  box-shadow: inset 0 -2px #b53224;
}

.ptro-icon-btn, .ptro-color-btn {
  border-bottom: none;
  box-shadow: none;
}


button:active {
  top: 1px;
  outline: none;
  box-shadow: none;
}

button[disabled] {
    background: #95a5a6;
    border-bottom-color: #8b9798;
    box-shadow: inset 0 -2px #8b9798;
}


/* settings menu */

.settingsMenu {
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
}
.settingsMenu::before {
  background-image: url('img/cog.svg');
  display: inline-block;
    background-size: 0.9em 0.9em;
    width: 0.9em; 
    height: 0.9em;
    content:"";
}

.settingsMenu ul {
    position: absolute;
    display: none;
    font-family: 'Oswald';
    background: #ecf0f1;
    border-radius: 5px;
    z-index: 15;
}

.settingsMenu.left ul {
    left: 0px;
    min-width: 115px;
      font-size: 0.78em;
}

.settingsMenu.right ul {
    right: 0px;
    min-width: 110px;
      font-size: 1.08em;

}

.settingsMenu.right li {  
  text-align: right;
}


.settingsMenu.right:hover ~ span,
.settingsMenu.right:active ~ span {
    text-shadow: 1px 1px 1px #c0392b;
}

.settingsMenu button {
  background: none;
  border: 0;
  color: black;
  text-shadow: none;
  box-shadow: none;
}

.settingsMenu button:hover,
.settingsMenu button:active {
    color: #c0392b;
    text-shadow: 1px 1px 1px black;
}

.settingsMenu:hover ul,
.settingsMenu:active ul {
  display: inline;
}

.wait, .wait * { cursor: wait !important; }