.moveAndPresentationSection {
  flex: 22 1 620px;
  order: 2;
  display: flex;
  flex-direction: column;
  min-width: 620px;
}

.moveRow {
    font-family: 'Oswald';
    font-size: 1.8em;
    position: relative;
}

.moveRow .textInput {
  width: 75%;
  vertical-align: bottom;
  resize: none;
}

.charactersRemaining {
  font-size: 12px;
  color: maroon;
  position: absolute;
  top: 2px;
  right: calc(25% - 54px);
}

.moveIsLast {
  color: #ff4e3a;
  position: absolute;
  font-size: 0.45em;
  bottom: 2.8em;
  line-height: 1em;
}

.moveUser {
  color: #c0392b;
  vertical-align: bottom;
  position: relative;
  display: inline-block;
  margin-right: 0.2em;
}
.moveRowYou .moveUser {
  min-width: 1.8em;
  min-height: 1.6em;
}
.moveUser::after {
    content: ": ";
}

.pageTimer {
  background-color: #2ecc71;
  margin: 10px -7px -7px -7px;
  padding-bottom: 4px;
  max-width: 150px;
}

.timerSoon {
  background-color: #f39c12;
}

.timerImminent {
  background-color: #ff4e3a;
}


@keyframes noopacity {
  to { opacity: 0; }
}
.tower .moveUser {
  position: absolute;
  top: 6px;
  background-color: #2ecc71;
  border-radius: 0.2em;
  margin-left: 0.2em;
  margin-top: 0.2em;
  padding: 0.2em;
  opacity: 0.92;
  line-height: 1em;
  animation: noopacity 1s ease-in 3s forwards;
}

.moveIndex {
    font-size: 0.6em;
    color: #7f8c8d;
    margin-right: 0.2em;
}
.moveSet {
  background-color: #ecf0f1;
  overflow: hidden;
}

.presentationSet {
  background-color: #2ecc71;
}

.moveAndPresentationSection ul {
  margin: 0 0 8px 0;
}

.tower {
  line-height: 0;
}


.pageImage {
  height: 450px;
  width: 600px;
}

.bigimage .pageImage {
  height: 700px;
  width: 900px;
}

.tower > div:not(:only-child) .pageImage {
  height: 650px;
  object-position: center bottom;
  object-fit: none;
}

.tower > div:not(:first-child) .pageImage {
  height: 450px;
}

.tower > div:last-child:not(:only-child) .pageImage {
  height: 250px;
}


.drawDiv {
  position: relative;
  height: 490px;
  width: 600px;
  display: inline-block;
  margin-bottom: -0.4em;
  touch-action: none;
}

.bigimage .drawDiv {
  height: 740px;
  width: 900px;
}

.hasTower {
  height: 690px;
}

.drawDiv button {
  color: black;
}

.waitingSet {
  background-color: #ecf0f1;
  font-family: 'Oswald';
  font-size: 1.8em;
}

.announcementSet {
  background-color: #ff7d6f;
  font-family: 'Oswald';
  font-size: 1.8em;
}

.announcementSet::after {
  content: " (Click to dismiss)";
  color: #555;
  font-size: 0.6em;
}

.elapsed {
  position: absolute;
  bottom: 0;
  right: 4px;
  font-size: 11px;
}

.presentationImageGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.presentationImageGrid img {
  width: 24%;
  margin-bottom: 6px;
}