.bookCreationSection {
  background-color: #b8b8b8;
  padding: 10px;
}

.bookCreationSection h4 {
margin-top: 0.25em;
font-size: 1.5em;
}

.bookCreationSection h5 {
margin: 0.5em 0 0 0;
  font-variant: small-caps;
  font-family: 'Verdana';
  color: #003f7e;
  text-shadow: 0px 0px 1px #6d6d6d;
  font-size: 1.3em;
}

.bookCreationSection ul {
  margin-bottom: 0.25em;
  background-color: #34495e;
  border-radius: 7px;
  font-family: 'Oswald';
  font-size: 1.8em;
}

.bookCreationSection li {
  padding-left: 0.3em;
}

.bookCreationSection section {
  display: flex;
  flex-flow: row wrap;
}

.bookCreationSection section div {
  width: 300px;
  margin-right: 15px;
}

.bookCreationSection > p {
  margin: 0;
  font-size: 0.9rem;
}

.bookCreationSection > button {
  margin: 0.5em auto;
  padding: 0 1em;
  font-size: 1.8em;
  font-family: 'Oswald';
}

.isChosen {
  background-color: #f39c12;
}
.isChosen::before {
  content: "\2713\00A0";
}

.bookCreationSection input[type=range] {
  width: 100%;
  margin-top: 1em;
}

.bookCreationSection label {
  display: block;
  text-align: center;
  font-size: 1.8em;
  font-family: 'Oswald';
  color: black;
}

.optionsHeader {
  cursor: pointer;
  user-select: none;
}

.disclosure {
  font-variant-caps: normal;
  text-decoration: underline;
  font-size: 0.6em;
  color: #003f7e;
  text-shadow: none;
  font-weight: 400;
  margin-left: 0.3em;
}

.newLabel {
  font-size: 12px;
  font-variant: normal;
  font-weight: normal;
  color: #f39c12;
  text-shadow: none;
  border-radius: 0.4em;
  background-color: #003f7e;
  margin-left: 0.45em;
  padding: 2px;
  font-family: 'Verdana';
}

.disableSection > * {
  opacity: 0.3;
  pointer-events: none;
}
.disableSection h5 {
  opacity: 1;
}
.disableItem {
  opacity: 0.3;
  pointer-events: none;
}

.betaLocked {
  opacity: 1;
  font-size: 0.8em;
  pointer-events: revert;
}

.betaLocked a {
  color: inherit;
}

.betaLocked img {
  display: inline-block;
  max-height: 1.3em;
  margin-right: 0.3em;
  margin-left: 0.5em;
  vertical-align: bottom;
}

.moveClick {
  display: inline-block;
  background-color: rgba(0,0,0,0.2);
  border: 1px solid gray;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  padding: 0.1em 0.5em;
  font-weight: bold;
  font-size: 1.4em;
  margin: 0.1em 0.2em;
}

.moveClick.on {
  background-color: rgba(0,255,0,0.7);
  border-color: darkgreen;
}