

.descriptionSection, .noticeSection {
  margin: 0 10vw 0 10vw;
  position: relative;
  margin-bottom: 0.75em;
}

.descriptionSection {
  background-color: #bd91cf;
}

.noticeSection {
  background-color: #cbcf91;
}

.roomSection {
  background-color: #16a085;
}

.createRoomSection {
  background-color: #34495e;
}

.roomSection, .createRoomSection {
  font-family: 'Oswald';
  font-size: 1.8em;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.roomSection label {
  margin-bottom: 4px;
}

.roomSection input {
  margin-bottom: 8px;
}

.patreonContainer {
  display: flex;
  align-items: center;
}

.patreonContainer img {
  max-height: 80px;
}
.patreonContainer div {
  flex-grow: 1;
  margin: 0 1.5em;
}

.patreonContainer ul {
  list-style: disc;
  margin-left: 2em;
}

.newHighlight {
  font-family: "Sigmar One";
  color: #c0392b;
  text-shadow: black 2px 2px 2px;
  margin-right: 0.5em;
  font-size: 1.3em;
}

.languageChooser {
  position: absolute;
  right: 8px;
  top: 8px;
}

.languageChooser span {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
  margin-right: 1em;
}